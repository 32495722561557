/* USED UNDER FREE OPEN SOURCE LISENCE FROM https://loading.io/css/ */

/* ELLIPSIS */
.ldsEllipsis,
.ldsEllipsis div {
  box-sizing: border-box;
}
.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsEllipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: var(--banner);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


/* DUAL RING */

.ldsDualRing,
.ldsDualRing:after {
  box-sizing: border-box;
}
.ldsDualRing {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.ldsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid var(--banner);
  border-color: var(--banner) transparent var(--banner) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

