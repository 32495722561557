.container {
  background-color: var(--banner);
  max-height: 150px;
  height: 30vh;
  min-height: 150px;
  display: flex;
  color: white;
}
/*   
.titleContainer {
  margin-left: clamp(40px, 10vw, 10vw);
  min-width: 540px;
  display: flex;
} */

.navContainer {
  margin-left: 5vw;
  width: 40vw;
  min-width: 500px;

}

/* .title {
  font-family: "Gill Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 28px;
  margin-bottom: 5px;

} */

/* .subtitle {
  font-family: "Gill Sans", san-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  margin-left: clamp(5px, calc(5vw + 40px), calc(5vw + 40px));
  margin-top: 0px;
} */

.containerGrid {
  /* display: grid;  */
  /* grid-template-columns: repeat(5, 1fr); */
  /* grid-template-rows: repeat(2,1fr); */
  background-color: var(--banner);
  display: flex;
  max-height: 165px;
}

.logo {
  display: block;
  object-fit: contain;
  max-width: 400px;
  padding: 0.5em;
  transform: scaleX(-1);
}

.navbar {
  float: right;
  margin-right: 10em;
}

.headerContent {
  width: 80%;
}

.titleContainer {
  display: flex;
  align-items: center;
  max-height: 90px;
  margin-left: 2vw;
}

.subtitle {
  max-width: 16em;
  padding-left: 2em;
  /* padding-bottom: 0; */
  font-size: 20px;
}

.title {
  font-size: 48px;
  padding-left: 1em;
  /* text-shadow: 0px 4px 4px 0px #00000040; */
  text-shadow: 0px 5px 4px #00000040;
}

.title, .subtitle {
  color:#E2F0F3;
  font-weight: 400;
}
