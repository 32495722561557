.movableWrapper {
    /* background-color: blue; */
    pointer-events: none;
    /* height: 800px; */
    /* width */
}

.tallWrapper {
    height: 800px;
}

.shortWrapper {
    height: 400px;
}

.movableWrapper:active {
    cursor: grabbing;
    /* background-color: red; */
    pointer-events: all;
    /* margin-top:-10px; */
    /* margin-top:-200px; */
    /* padding: 3em; */
    /* margin-top:-3em; */
    /* padding-top:3em; */
}

.container {
    pointer-events: all;
    cursor: grab;
    /* padding: 2em 0 0 2em; */
    border: 1px solid lightgray;
    border-radius: 1em;
    overflow: hidden;
    white-space: nowrap;
    padding: 1.5em 1em;
    /* margin-top: 1em; */

    align-items: center;
    text-align: center;

    display: inline-flex;
    flex-direction: row;

    background-color: white;
    /* opacity: 80%; */
    position: relative;
    top: 100px;
    left: 100px;
    z-index: 10;
}

.container:active {
    /* padding:3em 2em; */
    /* padding-top: 3em; */
    /* margin-top: 3em; */
    /* position: absolute;
    top: 10px; */
    cursor: grabbing;
}

.panel {
    /* max-width: 20em;
    min-width: 10em;
    min-height: 20em; */
    flex: 1;
    margin: 0.5em;
    border-radius: 2em;
    position: relative;
    transition: color 0.4s linear, background-color 0.4s linear;
}

.panel:hover {
    /* background-color: var(--banner); */
    /* background-color: #98e1ee; */
    background-color: #e1eef1;
    /* color: white; */
    cursor: pointer;
}

.panel::after {
    content: " ";
    position: absolute;
    bottom: 10%;
    top: 10%;
    right: -0.5em;
    height: 90%;
    width: 1px;
    background-image: linear-gradient(var(--line) 70%, transparent);
}

.panel:last-child::after {
    display: none;
}

.selected {
    background-color: var(--selected);
    /* color: white; */
}

.selected:hover {
    background-color: var(--selected);
    /* color: black; */
}

.icons {
    position: absolute;
    display: flex;
    top: 0;
    right: 0.5em;
    gap: 0.1em;
}

.windowTitle {
    position: absolute;
    top: -0.25em;
    /* left: 4em; */
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0 0.25em 0;
    /* border-bottom: 1px var(--line) solid; */
}

.windowTitle::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 70%;
    background-image: linear-gradient(to right, transparent, var(--line) 30%, var(--line) 70%, transparent);
}

.hintImage {
    object-fit: contain;
    position: absolute;
    width: 90%;
    bottom:0.5em;
    left:50%;
    transform: translateX(-50%);
    max-height: 75%;
}

.hintDefinition, .helperText {
    /* position: absolute; */
    white-space: pre-wrap;
    /* overflow-wrap: break-word; */
    word-wrap: break-word;
    text-align: left;
    justify-content: center;
    padding-left: 1em;
    padding-right: 1em;
}

.helperCircle {
    position: absolute;
    border: 2px #BF6B04 solid;
    border-radius: 50%;
    display: block;
}

.helperText {
    color:#BF6B04;
}