.wrapper {
    padding: 0;
    margin-bottom: 0.2em;
    /* flex:1; */
    /* margin: 1em 0em 1.5em 2em; */
}

.selectionContainer {
    border: 1px solid lightgray;
    border-radius: 1em;
    /* overflow: hidden; */
    white-space: nowrap;
    padding: 0.3em 0.1em;
    /* display: inline-block; */
    /* display: inline-flex; */
    justify-content: center;
    align-items: center;
    /* vertical-align: middle; */
    /* margin: 0; */
}

.pillButton {
    padding: .25em 1em;
    border-radius: 1em;
    margin: 0 0.2em 0 0.2em;
    /* box-shadow: 1px 1px lightgray; */
    user-select: none; /* prevents highlighting*/
    transition: color 0.4s linear, background-color 0.4s linear;
    text-align: center;
}

.pillButton {
    background-color: var(--unselected);
    color: black;
    font-size: 0.8em;
}

.pillButton:hover {
    cursor: pointer;
    background-color: var(--selected);

}

.selected {
    background-color: var(--selected);
    /* color: white; */
    /* box-shadow: 1px 1px darkgray; */
}

.abbreviationSelected {
    border-bottom: 2px dotted black;
    transition: border-bottom 0.4s linear

}

.abbreviationUnselected {
    border-bottom: 2px dotted black;
    transition: border-bottom 0.4s linear

}

.vertical {
    width: max-width;
    display: block;
    margin-bottom: 0.5em;
}

.vertical:last-child {
    width: max-width;
    display: block;
    margin-bottom: 0;
}

.abbreviation {
    /* text-decoration: underline dotted 2px; */
    /* border-bottom: 2px dotted black; */
    position: relative;
    /* float:inline-start; */
}

.abbreviation:before {
    content: attr(data-abbr);
    position: absolute;

    bottom:1.5em;
    left:50%;
    transform: translateX(-50%);

    border-radius: 0.5em;
    padding:0.4em;

    background:#4f4f4f;
    color:white;
    text-align: center;

    display:none;
}

.abbreviation:after {
    content: "";
    position:absolute;


    bottom:0.45em;
    left:50%;
    transform: translateX(-50%);

    border:10px solid #000;
    border-color: #4f4f4f transparent transparent transparent;

    display: none;
}

.abbreviation:hover:before, .abbreviation:hover:after {
    display: block;
    font-weight: bold;
    opacity: 1;
}

.abbreviationLeft {
    /* text-decoration: underline dotted 2px; */
    /* border-bottom: 2px dotted black; */
    position: relative;
    /* float:inline-start; */
}

.abbreviationLeft:before {
    content: attr(data-abbr);
    position: absolute;

    bottom:1.5em;
    left:50%;
    transform: translateX(-85%);

    border-radius: 0.5em;
    padding:0.4em;

    background:#4f4f4f;
    color:white;
    text-align: center;

    display:none;
}

.abbreviationLeft:after {
    content: "";
    position:absolute;


    bottom:0.45em;
    left:50%;
    transform: translateX(-50%);

    border:10px solid #000;
    border-color: #4f4f4f transparent transparent transparent;

    display: none;
}

.abbreviationLeft:hover:before, .abbreviationLeft:hover:after {
    display: block;
    font-weight: bold;
    opacity: 1;
}

.abbreviationRight {
    /* text-decoration: underline dotted 2px; */
    /* border-bottom: 2px dotted black; */
    position: relative;
    /* float:inline-start; */
}

.abbreviationRight:before {
    content: attr(data-abbr);
    position: absolute;

    bottom:1.5em;
    left:50%;
    transform: translateX(-15%);

    border-radius: 0.5em;
    padding:0.4em;

    background:#4f4f4f;
    color:white;
    text-align: center;

    display:none;
}

.abbreviationRight:after {
    content: "";
    position:absolute;


    bottom:0.45em;
    left:50%;
    transform: translateX(-50%);

    border:10px solid #000;
    border-color: #4f4f4f transparent transparent transparent;

    display: none;
}

.abbreviationRight:hover:before, .abbreviationRight:hover:after {
    display: block;
    font-weight: bold;
    opacity: 1;
    /* border-bottom: 2px dotted white; */
}