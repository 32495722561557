.container {
    padding: 0;
    flex: 1;
    vertical-align: bottom;
    margin-top: 0.2em;
    margin-left: 0.25em;
}

.checklistContainer {
    padding: 0;
    flex: 1;
    vertical-align: bottom;
}

.titleContainer{
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: row;
    max-width: 70%;
}

.title {
    flex:auto;
}

.button {
    width: 2em;
    height: 2em;
    align-self: center;
    background-color: white;
    border-radius: 50%;
    border: 1px solid darkgray;
    font-weight: bold;
    color: rgb(117, 117, 117);
    /* display: inline-block; */
    /* position: relative; */
    /* float: right; */
}

.button:hover {    
    /* background-color: #7ebac4; */
    /* color: white; */
    transition: linear 0.3s;
    cursor: pointer;
    background-color: var(--iconColor);
    color:white;
    /* border-color: white; */
}

.panelImage {
    position: absolute;
    
}