.roundButton {
    --size: 20px;
    --legLength: 10px;
    --legWidth: 2px;
    --backGroundColor: white;
    /* --activeColor: #689ca5; */
    --activeColor: var(--iconColor);

    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: var(--backGroundColor);
    border: solid var(--iconBorderColor) 2px;
    position: relative;
    vertical-align: center;
}

.roundButton:hover {
    transition: linear 0.3s;
    border-color: var(--activeColor);
    background-color: var(--activeColor);
    cursor: pointer;
}

.crossLeft, .crossRight {
    position: absolute;
    height: var(--legLength);
    width: var(--legWidth);
    background-color: var(--iconColor);
    top: 50%;
    left: 50%;
}

.crossLeft {
    transform: translate(-50%, -50%) rotate(45deg);
}

.crossRight {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.roundButton:hover .crossRight, 
.roundButton:hover .crossLeft {
    transition: linear 0.3s;
    background-color: white;
}