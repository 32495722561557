.lockButton {
    --size: 20px;
    --lockBodyHeight: 4px;
    --lockBodyWidth: 7px;
    --shackleRadius: 4px;
    --backGroundColor: white;
    --activeColor: #689ca5;


    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: var(--backGroundColor);
    border: solid var(--iconBorderColor) 2px;
    position: relative;
}

.lockButton:hover {
    transition: linear 0.3s;
    border-color: var(--activeColor);
    background-color: var(--activeColor);
    cursor: pointer;
}

.body {
    position: absolute;
    height: var(--lockBodyHeight);
    width: var(--lockBodyWidth);
    border: 2px var(--iconColor) solid;
    border-radius: 2px;
    transform: translate(-50%, -25%);
    overflow: hidden;
}

.lockButton:hover .body {
    transition: linear 0.3s;
    border-color: white;
}

.shackleLocked, .shackleUnlocked {
    position: absolute;
    height: var(--shackleRadius);
    width: var(--shackleRadius);
    border: 2px solid;
    border-color: var(--iconColor) transparent transparent var(--iconColor);
    border-radius: 50%;
}

.shackleLocked {
    transform: translate(-50%, -85%) rotate(45deg);
}

.shackleUnlocked {
    transform: translate(-50%, -85%) rotate(90deg);
}

.lockButton:hover .shackleLocked,
.lockButton:hover .shackleUnlocked {
    transition: linear 0.3s;
    border-color: white transparent transparent white;
}

