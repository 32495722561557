.outerContainer {
    border-radius: 10px;
    background-color: var(--skeleton);
    position: relative;
    transition: all 0.5s;
    margin-bottom: 20px;
}

.outerContainer:hover {
    /* transform: scale(1.15);
    transition-delay: 0.1s;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    z-index: 99;
    cursor: pointer; */
}

.innerContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform-origin: 50%;
    transition: all 0.5s;
    z-index: 30;
    border-radius: 10px;
    overflow: hidden;
}

.image {
    max-width: 100%;
}

.basicNameTag, .nameTag {
    font-family: "Gill Sans", sans-serif;
    font-size: 12px;
    
    color: white;
    /* background-color: var(--name-tag); */
    height: 25px;
    width: 100%;
    position: absolute;
    left: 0px;
    /* top: 180px; */
    bottom: -10px;
    padding: 4px;
    padding-left: 12px;
}

.basicNameTag {
    font-style:normal;
    font-weight: 700;
}

.nameTag {
    font-style: italic;
    font-weight: 800;
}

.portrait, .image, .nameTag, .basicNameTag {
    transition: all 0.5s;
    
}

.skeleton {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: cubic-bezier(.54,.15,.51,.98);
    /* background-color: #f6f7f8; */
    background-color: var(--skeleton);
    
    background: linear-gradient(
        to right,
        var(--skeleton) 0%, 
       white 5%, 
        var(--skeleton) 10%
    );

    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: relative;
}


.buttons{
    position: absolute;
    bottom: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.carouselButton {
    font-size: 1.25rem;
    background-color: transparent;
    border: none;
    /* color: white; */
    /* color: darkgray */
    cursor: pointer;
    opacity: 60%;
}

.carouselButton:hover{
    opacity: 100%;
}

@keyframes loading {
    0% {
        background-position: 0px 0px;
    }
    100% {
        background-position: 140px 0px;
    }    
}