.App{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.home{
    display: flex;
    flex-direction: row;
    width: 100%;
}
