.scrollableWrapper {
    /* scroll shadows */
    background:
        linear-gradient(white 30%,rgba(255, 255, 255, 0)) center top,
        linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0)) center top,
        radial-gradient(farthest-side at 50% 100%,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0)) center bottom;

    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;

    /* scroll styling */
    position: sticky;
    top: 1em;
    max-height: 95vh;
    min-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border-right:  1px solid var(--line);
    padding-right: 0.5em;

}

.container {
    /* posititioning */
    position: sticky;
    top: 1em;
    width: 25em;
    /* width: fit-content; */
    /* max-width: 25vw; */
    /* min-width: 28vw; */
    padding: 0 1em 0em 2em;
    z-index: 9;
}

/* 
.row {
    border-radius: 3px;
    padding: 8px;
}

.row label {
    padding: 6px;
} */

.row :hover {
    background-color: #abdee9;
    color: white;
    border-radius: 4px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}


.selectionContainer {
    border: 1px solid lightgray;
    border-radius: 1em;
    /* overflow: hidden; */
    padding: 0.5em 0.75em;
}

.icons {
    position: sticky;
    display: flex;
    justify-content: flex-end;
    top: 1em;
    margin-right: 0.5em;
}

h5 {
    margin-bottom: 0.5em;
}

h6 {
    margin-top: 0em;
    margin-left: 1em;
    margin-bottom: 1em;
}

h2 {
    margin-top: 0.5em;
    margin-bottom: 0.3em;
}

.headingButtonContainter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* align-self: center; */
    /* vertical-align: center; */
}

.clearFiltersButton {
    --backGroundColor: white;
    --activeColor: #689ca5;

    background-color: var(--backGroundColor);
    border: solid var(--iconBorderColor) 1px;
    margin-top: 0.4em;
    margin-right: 2.5em;
    /* margin-bottom: 0.6em; */
    padding-right: 1em ;
    padding-left: 1em;
    border-radius: 1em;
    /* color:rgba(0, 0, 0, 0.2); */
    /* color: black; */
    color: rgb(90, 80, 80);
    height: 20px;
    align-items: center;
}

.clearFiltersButton:hover {
    transition: linear 0.3s;
    border-color: var(--activeColor);
    background-color: var(--activeColor);
    color: white;
    cursor: pointer;
}

.moreFeaturesContainer {
    padding-bottom: 2em;
}

.moreFeaturesHeader {
    margin-top: 1.25em;
}

.divider {
    /* border: 2px solid var(--filterDivider); */
    border-top: 1px solid var(--line);
}